import SocketIO from 'socket.io-client';
import { CanvasRecord, Placement } from '../common/types/canvas';
import { ViewCanvas } from './canvas';

const socket = SocketIO();
const canvas = new ViewCanvas();
let placerRequestResolve: any;
let placerRequest: any;

canvas.initialize();

socket.on('connect', () => {
  console.log('connected');
});

socket.on('me', (user) => {
  console.log(user);
  canvas.setUser(user);
});

socket.on('plane', (plane) => {
  canvas.fill(plane.size, plane.canvas);
});

socket.on('changes', (changes: CanvasRecord[]) => {
  console.log(changes);
  changes
    .sort((a, b) => a.ts - b.ts)
    .forEach((change) => {
      canvas.setPixel(change.x, change.y, change.color);
    });
});

socket.on('colorack', ({ x, y, c }: Placement) => {
  canvas.setPixel(x, y, c);
});

socket.on('placerat', (response: CanvasRecord) => {
  if (placerRequest) {
    placerRequestResolve(response);
  }
});

canvas.registerOnPlace((placement) => {
  socket.emit('color', placement);
});

canvas.registerGetPlacer((x: number, y: number) => {
  if (placerRequest) {
    placerRequestResolve(null);
  }

  placerRequest = new Promise((resolve) => {
    placerRequestResolve = resolve;
  });

  socket.emit('getplacer', { x, y, reqt: Date.now() });
  return placerRequest;
});
