export function convertHex(hex: number): { r: number; g: number; b: number } {
  return {
    r: (hex >> 16) & 255,
    g: (hex >> 8) & 255,
    b: hex & 255,
  };
}

export function hexToString(hex: number): string {
  const { r, g, b } = convertHex(hex);
  return `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}

export function to2D(index: number, size: number): { x: number; y: number } {
  const y = index / size;
  const x = index % size;
  return { x, y };
}

export function to1D(x: number, y: number, size: number): number {
  return y * size + x;
}

export function storeHex(hex: string): number {
  return Number(hex.replace('#', '0x'));
}
