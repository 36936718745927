export const $ = ($selector: string): HTMLElement => {
  let element: HTMLElement | null = null;
  if ($selector.includes('<')) {
    const elementName = $selector.match(/^<([^\s>]*)/);
    if (!elementName?.length) {
      throw new Error('Could not select element');
    }
    element = document.createElement(elementName[1]);

    const attrs = $selector.match(/([^=\s]*="[^">]*")/g);
    if (attrs?.length) {
      const propMap = attrs
        .map((item) => item.split('='))
        .reduce<Record<string, any>>(
          (obj, arr) => ({ ...obj, [arr[0]]: arr[1].replace(/"/g, '').trim() }),
          {},
        );
      if (propMap && Object.keys(propMap).length) {
        Object.keys(propMap).forEach((attr) => {
          element?.setAttribute(attr, propMap[attr]);
        });
      }
    }
  } else {
    element = document.querySelector($selector);
  }
  if (element === null) {
    throw new Error('Could not select element');
  }
  return element;
};
