export function clamp(x: number, min: number, max: number): number {
  return Math.min(Math.max(x, min), max);
}

export function debounce(func: Function, timeout = 300) {
  let timer: any;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
